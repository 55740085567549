<template>
    <BreadCrumb :items="breadCrumbItems" />
    <div>
        <section class="wrapper content">
            <header>
                <h1>Política de Privacidade</h1>
                <p class="x-large">Última atualização e publicação: 06 de Março de 2025</p>
            </header>
            <div>
                <h4>1. Introdução</h4>
                <p>Esta Política de Privacidade descreve como o Calculico coleta, usa, divulga e protege suas informações pessoais ao utilizar nosso site e serviços.</p>
                <h4>2. Coleta de Informações</h4>
                <p>Coletamos informações pessoais que você nos fornece diretamente, como nome, e-mail, e outras informações que você opta por compartilhar, por exemplo, ao entrar em contato conosco. Também coletamos informações automaticamente, como dados de navegação, cookies e informações sobre o dispositivo, que não são individualizadas e não podem ser vinculadas a um usuário específico.</p>
                <h4>3. Uso de Informações</h4>
                <p>Utilizamos suas informações para fornecer e melhorar nossos serviços, responder às suas solicitações, personalizar sua experiência no site e para fins de marketing e publicidade.</p>
                <p>Além disso, usamos suas informações para apresentar anúncios relevantes. Para isso, podemos compartilhar dados limitados e não individualizados com nossos parceiros de publicidade, como Google AdSense e Clever Advertising, para veiculação de anúncios personalizados. Esses dados podem incluir informações como cookies, identificadores de dispositivo e dados de navegação, mas não incluem informações pessoais identificáveis.</p>
                <h4>4. Compartilhamento de Informações</h4>
                <p>Não compartilhamos suas informações pessoais com terceiros, exceto conforme necessário para fornecer nossos serviços, cumprir obrigações legais, ou com seu consentimento.</p>
                <p>Para a exibição de anúncios, podemos compartilhar informações limitadas e não identificáveis com parceiros de publicidade, conforme descrito na seção de Uso de Informações. Esses parceiros podem usar essas informações para oferecer anúncios que sejam de maior interesse para você.</p>
                <h4>5. Proteção das Informações</h4>
                <p>Adotamos medidas de segurança apropriadas para proteger suas informações pessoais contra acesso não autorizado, alteração, divulgação ou destruição.</p>
                <h4>6. Cookies</h4>
                <p>Usamos cookies para melhorar a funcionalidade do nosso site, analisar o uso do site e personalizar conteúdo e anúncios. Os anúncios são fornecidos por serviços de terceiros, como o Google AdSense, que podem utilizar cookies para personalizar os anúncios que você vê com base em sua navegação anterior.</p>
                <p>Você pode configurar seu navegador para recusar cookies, mas isso pode limitar sua capacidade de usar algumas funcionalidades do site. Para mais informações sobre como o Google utiliza cookies e dados de anúncios, visite a <a href="https://policies.google.com/technologies/ads" target="_blank">Política de Privacidade do Google</a>.</p>
                <h4>7. Direitos do Usuário</h4>
                <p>Você tem o direito de acessar, corrigir, atualizar ou solicitar a exclusão de suas informações pessoais. Para exercer esses direitos, entre em contato conosco pelo e-mail fornecido abaixo.</p>
                <h4>8. Alterações nesta Política</h4>
                <p>Podemos atualizar esta Política de Privacidade periodicamente. Quaisquer alterações serão publicadas nesta página com a data da última atualização.</p>
                <h4>9. Contato</h4>
                <p>Se você tiver dúvidas sobre esta Política de Privacidade, entre em contato conosco pelo e-mail: <a href="mailto:contato@calculico.com.br">contato@calculico.com.br</a>.</p>
            </div>
        </section>
    </div>
</template>
<script>
import BreadCrumb from '@/components/layout/breadCrumb.vue';

export default {
    name: 'PrivacyPage',
    components: {
        BreadCrumb
    },
    data() {
        return {
            breadCrumbItems: [
                { label: 'Política de Privacidade', url: '/privacidade' }
            ]
        };
    },
    methods: {
        // Your methods go here
    },
    mounted() {
        // Code to run when the component is mounted goes here
    },
    // Other component options go here
};
</script>

<style scoped>
.content{
    padding-top: 8rem;
    max-width:800px ;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    margin: 0 auto;

    p{
        margin-bottom: 2rem;
    }
    h4{
        margin-bottom: 1.2rem;
    }
}
</style>