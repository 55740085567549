<template>
  <BreadCrumb :items="breadCrumbItems" />
  <div class="wrapper content">
    <adHorizontal />
    <CalculatorHeader emoji="💓" title="Calculadora de Frequência Cardíaca e Zona de Queima de Gordura"
      subtitle="Descubra sua zona de queima de gordura, calcule sua frequência cardíaca ideal, mínima e máxima para diferentes intensidades de exercício e otimize seus treinos." />
    <div class="calculator-body">
      <form @submit.prevent="calculateHeartRates">
        <div class="form-section vertical">
          <div class="form-group">
            <label for="age">Idade:</label>
            <input type="number" v-model="age" id="age" placeholder="anos" required />
          </div>
          <div class="form-group">
            <label for="restingHeartRate">Frequência Cardíaca em Repouso (bpm):</label>
            <input type="number" v-model="restingHeartRate" id="restingHeartRate" placeholder="bpm" required />
            <caption>Para saber sua frequência cardíaca em repouso, verifique seus batimentos cardíacos ao acordar. Caso
              não saiba esse valor neste momento, considere um valor entre 70 e 90.</caption>
          </div>
        </div>
        <AppButton label="Calcular" type="primary" size="large" />
      </form>
      <div v-if="resultsVisible" class="results-separator">
        <h4>Resultado</h4>
        <hr />
      </div>
      <div v-if="resultsVisible" class="result" ref="result">
        <h5>Frequência Cardíaca Ideal por Intensidade</h5>
        <table class="results-table">
          <thead>
            <tr>
              <th>Intensidade</th>
              <th>BPM</th>
              <th>Faixa</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="range in heartRateRanges" :key="range.intensity">
              <td>{{ range.intensity }}</td>
              <td>{{ range.bpm }}</td>
              <td>{{ range.rangeType }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!--PRODUCT OFFERS AFFILIATE PROGRAM AMAZON-->
  <div class="category product-shelf">
    <div class="wrapper">
      <div class="category-header">
        <div class="category-title">
          <img src="@/assets/images/icons/gem-solid.svg" width="32" alt="Category Icon" class="category-icon" />
          <h3>Monitores de frequência cardíaca recomendados</h3>
        </div>
        <p class="large">Selecionamos monitores de frequência cardíaca e smartwatches que você pode gostar:</p>
      </div>
      <div class="calculator-cards">
        <ProductCard title="SAMSUNG Galaxy FIT 3 Tela AMOLED de 1,6 polegadas"
          description="Mais de 100 modos de exercício | Vida útil da bateria de 14 dias | Mais de 100 mostradores de relógio | Mais de 100 modos de exercício | Modelo internacional - (preto)"
          url="https://amzn.to/3Lmo3q9" image="https://m.media-amazon.com/images/I/41s4LbRyUDL._AC_SX569_.jpg" />
        <ProductCard title="Xiaomi Mi Band 8 Active versão global (Preto)"
          description="Monitoramento de frequência cardíaca de pulso | Mais de 50 modos esportivos | Bateria com duração de 14 dias | Tela ultragrande de 1,47 polegadas | Carregamento magnético | Conexão Bluetooth"
          url="https://amzn.to/4690dry" image="https://m.media-amazon.com/images/I/71XmFzlEc6L._AC_SX679_.jpg" />
        <!--AMAZON PRIME CARD
        <div class="grid-ad amazon-prime">
          <a href="https://amzn.to/3zJaqhU">
            <img src="@/assets/images/amazon-prime-day3.jpg" alt=""></a>
          <p>Usuários do Calculico tem 30 dias grátis para testar Amazon Prime e aproveitar ofertas e benefícios, como
            entrega grátis e mais rápida.</p>
          <AppButton label="Testar Grátis por 30 dias" type="secondary" href="https://amzn.to/3zJaqhU" />
          <caption>Você será direcionado para a página da Amazon Prime</caption>
        </div>
        AMAZON PRIME CARD END-->
        <ProductCard title="Monitor Cardíaco de Pulso com GPS Garmin Forerunner 55"
          description="Monitoramento de frequência cardíaca no pulso | GPS integrado | Monitoramento de oxigênio no sangue | Monitoramento de estresse | Monitoramento de sono | Corrida, ciclismo, natação e mais"
          url="https://amzn.to/3xW8hz6" image="https://m.media-amazon.com/images/I/61llwZ-kysL._AC_SX466_.jpg" />
        <ProductCard title="Smartwatch Xiaomi Redmi Watch 4 Hyper OS M2315W1 Silver Gray"
          description="Monitoramento de frequência cardíaca de pulso | Tela de 1,97 polegadas | Comunicação sem fio via Bluetooth | GPS e GLONASS integrados | Monitaramento de sono e níveis de estresse "
          url="https://amzn.to/3Wmy8tf" image="https://m.media-amazon.com/images/I/518XRF4kD6L._AC_SX466_.jpg" />
      </div>
      <div class="alert-warning">
        <img src="@/assets/images/icons/exclamation-circle-solid.svg" alt="">
        <p class="small">Os produtos acima são selecionados e recomendados por nós, mas são vendidos e entregues de
          acordo com as informações no site do parceiro, no caso, a Amazon. O Calculico não é responsável pela venda ou
          entrega. Como associado da Amazon, recebemos por compras qualificadas.</p>
      </div>
    </div>
  </div>
  <!--END OF OFFERS AFFILIATE PROGRAM AMAZON-->
  <OtherCalculators :categoryId="3" />
  <div class="calculatorInfo wrapper content">
    <section class="info-block">
      <header>
        <h2>O que é a Frequência Cardíaca Ideal?</h2>
      </header>
      <p class="large">A frequência cardíaca ideal é a faixa de batimentos cardíacos que você deve manter durante o
        exercício para maximizar os benefícios do treino cardiovascular, ou para otimizar a queima de gordura. Ela é baseada na sua idade e frequência
        cardíaca em repouso. Entender e monitorar essa frequência é crucial para otimizar os treinos, melhorar a saúde
        cardiovascular e garantir a segurança durante o exercício.</p>
    </section>

    <section class="info-block">
      <header>
        <h2>Benefícios de Treinar na Frequência Cardíaca Ideal para Seus Objetivos</h2>
      </header>
      <p class="large">Treinar dentro da faixa ideal de frequência cardíaca oferece diversos benefícios, como:</p>
      <ul class="large">
        <li><strong>Melhoria da Saúde Cardiovascular:</strong> Fortalece o coração e melhora a eficiência do sistema
          circulatório.</li>
        <li><strong>Queima Eficiente de Calorias:</strong> Maximiza a queima de gordura durante o exercício.</li>
        <li><strong>Monitoramento da Intensidade:</strong> Ajuda a manter a intensidade do exercício dentro de uma faixa
          segura e eficaz.</li>
        <li><strong>Prevenção de Lesões:</strong> Reduz o risco de overtraining e lesões associadas ao excesso de
          esforço.</li>
        <li><strong>Aumento da Resistência:</strong> Melhora a capacidade aeróbica e a resistência física.</li>
      </ul>
    </section>

    <section class="info-block">
      <header>
        <h2>Como Calcular a Frequência Cardíaca Ideal?</h2>
      </header>
      <p class="large">Uma das fórmulas mais comuns para calcular a frequência cardíaca ideal é a Fórmula de Karvonen.
        Aqui está o passo a passo:</p>
      <ol class="large">
        <li><strong>Calcule sua Frequência Cardíaca Máxima (FCM):</strong> 220 menos a sua idade.</li>
        <li><strong>Determine sua Frequência Cardíaca de Repouso (FCR):</strong> Meça seus batimentos cardíacos pela
          manhã, antes de se levantar.</li>
        <li><strong>Utilize a Fórmula:</strong> Frequência Cardíaca Ideal = Frequência Cardíaca de Repouso + (Frequência
          Cardíaca Máxima - Frequência Cardíaca de Repouso) × Intensidade do Exercício</li>
      </ol>
      <p class="large">A intensidade do exercício é expressa como uma porcentagem, variando de 50% a 85% da sua FCM.</p>
    </section>

    <section class="info-block">
      <header>
        <h2>Faixas de Frequência Cardíaca e Seus Benefícios</h2>
      </header>
      <table>
        <thead>
          <tr>
            <th>Intensidade</th>
            <th>Faixa</th>
            <th>Benefícios</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>50-60%</td>
            <td>Aquecimento</td>
            <td>Prepara o corpo para o exercício, aumentando gradualmente a frequência cardíaca.</td>
          </tr>
          <tr>
            <td>60-70%</td>
            <td>Queima de Gordura</td>
            <td>Zona ideal para a queima de gordura, utilizando os depósitos de gordura como principal fonte de energia.
            </td>
          </tr>
          <tr>
            <td>70-80%</td>
            <td>Cardio Moderado</td>
            <td>Melhora da capacidade cardiovascular e resistência.</td>
          </tr>
          <tr>
            <td>80-90%</td>
            <td>Anaeróbico</td>
            <td>Aumento da capacidade anaeróbica e força muscular.</td>
          </tr>
          <tr>
            <td>90-100%</td>
            <td>Esforço Máximo</td>
            <td>Utilizado em treinos de alta intensidade, melhorando a potência e a velocidade.</td>
          </tr>
        </tbody>
      </table>
    </section>

    <section class="info-block">
      <header>
        <h2>Como Monitorar a Frequência Cardíaca Durante o Exercício</h2>
      </header>
      <p class="large">Para monitorar sua frequência cardíaca durante o exercício, você pode usar dispositivos como
        monitores de frequência cardíaca, relógios inteligentes ou medir manualmente. Manter-se dentro da faixa ideal
        durante o exercício ajuda a garantir que você está treinando de forma eficaz e segura.</p>
    </section>

    <section class="info-block">
      <header>
        <h2>Dicas para Maximizar os Benefícios do Treinamento com Frequência Cardíaca Ideal</h2>
      </header>
      <ul class="large">
        <li><strong>Use um Monitor de Frequência Cardíaca:</strong> Utilize dispositivos como relógios ou monitores de
          peito para acompanhar sua frequência cardíaca em tempo real.</li>
        <li><strong>Ajuste a Intensidade:</strong> Baseie-se na frequência cardíaca para ajustar a intensidade dos
          exercícios, garantindo que permaneça na zona desejada.</li>
        <li><strong>Varie os Treinos:</strong> Incorpore diferentes zonas de frequência cardíaca em sua rotina de
          exercícios para melhorar a resistência e a força.</li>
        <li><strong>Inicie com Aquecimento:</strong> Comece seu treino em uma zona de aquecimento (50-60% da frequência
          cardíaca máxima) para preparar seu corpo.</li>
        <li><strong>Use Intervalos:</strong> Incorpore intervalos de alta intensidade (80-90%) para aumentar a
          capacidade anaeróbica e queimar mais calorias.</li>
        <li><strong>Recupere Corretamente:</strong> Utilize a faixa de aquecimento (50-60%) para recuperação ativa entre
          intervalos ou no final do treino.</li>
        <li><strong>Consulte um Profissional:</strong> Antes de iniciar um novo regime de exercícios, consulte um médico
          ou um profissional de saúde para garantir que você está em uma faixa segura de frequência cardíaca.</li>
      </ul>
    </section>

    <section class="info-block">
      <header>
        <h2>Arritmia e Controle da Frequência Cardíaca</h2>
      </header>
      <p class="large">Nosso coração está constantemente ativo, seja durante momentos tranquilos ou de preocupação, em
        atividades físicas ou em repouso. Às vezes, o ritmo cardíaco pode se tornar irregular, muito lento ou muito
        rápido, o que caracteriza as arritmias. Essas variações afetam milhões de brasileiros e são uma causa
        significativa de mortes súbitas no país.</p>
      <p class="large">É comum sentir o coração bater mais forte em situações do dia a dia, mas se isso ocorrer em
        repouso ou acompanhado de sintomas, é essencial buscar atendimento médico. A frequência cardíaca pode variar
        devido a fatores como alimentação, sono, medicamentos, atividades físicas ou mentais, e até crises de ansiedade.
        A faixa normal é de 50 a 90 batimentos por minuto (bpm).</p>
      <p class="large">Prevenir arritmias é crucial para evitar complicações graves como morte súbita ou derrames.
        Manter o coração saudável envolve adotar hábitos saudáveis: reduzir o estresse, ter uma alimentação balanceada,
        controlar o peso, moderar no consumo de álcool e energéticos, não fumar e praticar exercícios regularmente.
        Cuidar da saúde emocional e realizar exames preventivos são medidas fundamentais.</p>
      <p class="large">Se uma arritmia for diagnosticada, o tratamento pode incluir mudanças no estilo de vida, uso de
        medicamentos ou procedimentos específicos como a ablação ou o implante de marca-passo. Hoje, todas as arritmias
        têm tratamento eficaz, proporcionando uma melhor qualidade de vida para os pacientes.</p>
    </section>

    <section class="info-block">
      <header>
        <h2>Frequência Cardíaca Ideal para Queimar Gordura</h2>
      </header>
      <p class="large">Para quem deseja emagrecer, a frequência cardíaca ideal durante o treino deve estar entre 60% e
        75% da frequência cardíaca máxima (FCM). Esta intensidade de treino melhora o condicionamento físico e utiliza
        mais gordura como fonte de energia, contribuindo para a perda de peso.</p>
      <table>
        <thead>
          <tr>
            <th>Idade</th>
            <th>FC Ideal para Homens</th>
            <th>FC Ideal para Mulheres</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>20</td>
            <td>120 - 150</td>
            <td>123 - 154</td>
          </tr>
          <tr>
            <td>25</td>
            <td>117 - 146</td>
            <td>120 - 150</td>
          </tr>
          <tr>
            <td>30</td>
            <td>114 - 142</td>
            <td>117 - 147</td>
          </tr>
          <tr>
            <td>35</td>
            <td>111 - 138</td>
            <td>114 - 143</td>
          </tr>
          <tr>
            <td>40</td>
            <td>108 - 135</td>
            <td>111 - 139</td>
          </tr>
          <tr>
            <td>45</td>
            <td>105 - 131</td>
            <td>108 - 135</td>
          </tr>
          <tr>
            <td>50</td>
            <td>102 - 127</td>
            <td>105 - 132</td>
          </tr>
          <tr>
            <td>55</td>
            <td>99 - 123</td>
            <td>102 - 128</td>
          </tr>
          <tr>
            <td>60</td>
            <td>96 - 120</td>
            <td>99 - 124</td>
          </tr>
          <tr>
            <td>65</td>
            <td>93 - 116</td>
            <td>96 - 120</td>
          </tr>
          <tr>
            <td>70</td>
            <td>90 - 113</td>
            <td>94 - 117</td>
          </tr>
          <tr>
            <td>75</td>
            <td>87 - 109</td>
            <td>91 - 113</td>
          </tr>
        </tbody>
      </table>
      <p class="large">Para calcular a frequência cardíaca ideal para queimar gordura, utilize as seguintes fórmulas:
      </p>
      <p class="large"><strong>Homens:</strong> 220 - idade, depois multiplique o valor por 0.60 e
        0.75.<br><strong>Mulheres:</strong> 226 - idade, depois multiplique o valor por 0.60 e 0.75.</p>
      <p class="large">Por exemplo, uma mulher de 30 anos deve fazer os seguintes cálculos:</p>
      <ul class="large">
        <li>226 - 30 = 196</li>
        <li>196 x 0.60 = 117 (FC mínima ideal para emagrecer)</li>
        <li>196 x 0.75 = 147 (FC máxima ideal para emagrecer)</li>
      </ul>
      <p class="large">Outra maneira de determinar a frequência cardíaca ideal é através de testes de esforço, como o
        teste de ergoespirometria, que indicam os valores ideais de FC de treino e outros parâmetros importantes, como a
        capacidade de VO2.</p>
    </section>

    <section class="info-block">
      <header>
        <h2>Considerações Finais</h2>
      </header>
      <p class="large">Entender e monitorar a frequência cardíaca ideal é fundamental para otimizar os treinos e
        alcançar melhores resultados de saúde e desempenho físico. Utilize as informações sobre frequência cardíaca para
        personalizar seus treinos e garantir que está trabalhando na intensidade correta para seus objetivos. Manter-se
        informado sobre a saúde do coração e adotar um estilo de vida saudável são passos essenciais para evitar
        arritmias e garantir uma vida longa e ativa.</p>
    </section>
  </div>





</template>
<script>
import CalculatorHeader from '@/components/layout/calculatorHeader.vue';
import AppButton from '@/components/common/appButton.vue';
import BreadCrumb from '@/components/layout/breadCrumb.vue';
import OtherCalculators from '@/components/calculator/otherCalculators.vue';
import ProductCard from '@/components/monetization/productCard.vue';
import adHorizontal from '@/components/monetization/adHorizontal.vue';

export default {
  name: 'CalculadoraFrequenciaCardiaca',
  components: {
    CalculatorHeader,
    AppButton,
    BreadCrumb,
    OtherCalculators,
    ProductCard,
    adHorizontal
  },
  data() {
    return {
      breadCrumbItems: [
        { label: 'Calculadora de Frequência Cardíaca Ideal', url: '/calculadora-frequencia-cardiaca' }
      ],
      age: null,
      restingHeartRate: null,
      heartRateRanges: [],
      resultsVisible: false,
    };
  },
  methods: {
    calculateHeartRates() {
      const maxHeartRate = 220 - this.age;
      const intensityLevels = [0.5, 0.6, 0.7, 0.8, 0.9, 1.0];
      const rangeTypes = ["Aquecimento", "Queima de Gordura", "Cardio Moderado", "Anaeróbico", "Esforço Máximo", "Máximo"];

      this.heartRateRanges = intensityLevels.map((level, index) => {
        const bpm = Math.round(((maxHeartRate - this.restingHeartRate) * level) + this.restingHeartRate);
        return {
          intensity: `${Math.round(level * 100)}%`,
          bpm,
          rangeType: rangeTypes[index]
        };
      });

      this.resultsVisible = true;
      this.$nextTick(() => {
        this.scrollToResult();
      });
    },
    scrollToResult() {
      const resultSection = this.$refs.result;
      if (resultSection) {
        resultSection.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.results-table {
  @extend p;
  width: 100%;
  border-collapse: collapse;
  margin-top: 1.6rem;
  text-align: center;

  th,
  td {
    padding: 8px;
    border-bottom: 1px solid var(--Neutral-2, #CECCCA);
  }

  th {
    text-align: center;
    font-weight: 500;
  }

  td {
    text-align: center;
  }

  tbody tr {
    &:nth-child(1) {
      background: $warning-0;
    }

    &:nth-child(2) {
      background: $warning-1;
    }

    &:nth-child(3) {
      background: $warning-2;
    }

    &:nth-child(4) {
      background: $danger-0;
    }

    &:nth-child(5) {
      background: $danger-1;
    }

    &:nth-child(6) {
      background: $danger-2;
    }
  }
}
</style>
